.medium-insert-embeds-input {
    position: relative;
    color: #ccc;
    z-index: 1;
    text-align: left;
}


.medium-insert-embeds-placeholder {
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-placeholder);
        color: #ccc;
        text-align: center;
              width: 100%;
    }
}

.medium-insert-embeds-selected {
    .medium-insert-embed {
        outline: 2px solid #000;
    }
}

.medium-insert-embeds-toolbar {
    display: none !important;

}

.medium-insert-embeds {
    .medium-insert-embeds-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
